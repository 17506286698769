<template>
  <div class="main" data-title="系统操作日志" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">系统操作日志</h2>
      </div>
    </div>

    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">操作内容</div>
        <div class="jue-card-btns">
          <el-date-picker
            @change="setDate"
            align="right"
            end-placeholder="结束日期"
            format="yyyy年MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="date_begin_end"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>

      <div class="table-wrapper">
        <el-table
          :data="dataList"
          :style="'width: '+$store.state.tableWidth"
          class="table"
          style="width: 100%"
        >
          <el-table-column label="ID" prop="sys_id"></el-table-column>
          <el-table-column label="操作员" prop="uid">
            <template slot-scope="scope">{{ $store.state.userList[scope.row.uid][0] }}</template>
          </el-table-column>
          <el-table-column label="操作内容" prop="action">
            <template slot-scope="scope">{{authName[scope.row.action]}}</template>
          </el-table-column>
          <el-table-column label="时间" prop="created_at"></el-table-column>
          <!-- <el-table-column label="查看" width="70">
            <template slot-scope="scope">
              <el-button @click="showDetail(scope.row.sys_id)" size="small" type="text">查看</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="key.count"
          :total="total"
          @current-change="search"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      date_begin_end: [],
      total: 0,
      dataList: [],
      authName: {},
      key: {
        page: 1,
        count: 15,
        begin_date: '',
        end_date: ''
      }
    }
  },
  mounted() {
    this.getAuthName()
    this.getDataList()
  },
  methods: {
    showDetail(sys_id){
      this.$axios.post('/log/detail', {
        sys_id: sys_id
      }).then(res => {
        if (res.data.code == 200) {
          console.log(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    setDate() {
      this.key.page = 1
      if (this.date_begin_end == null) {
        this.key.begin_date = ''
        this.key.end_date = ''
      } else {
        this.key.begin_date = this.date_begin_end[0]
        this.key.end_date = this.date_begin_end[1]
      }
      this.getDataList()
    },
    search(val) {
      this.key.page = val
      this.getDataList()
    },
    getAuthName() {
      this.$axios.post('/index/api').then(res => {
        if (res.data.code == 200) {
          this.authName = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getDataList() {
      this.$axios.post('/log/lists', this.key).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.data.total
          this.dataList = res.data.data.list
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.label-block {
  /deep/ .el-form-item__content {
    margin-top: 36px;
  }
}
.table-wrapper {
  margin: -20px -20px 0;
}

.form {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  /deep/ .el-select,
  /deep/ .el-date-editor {
    width: 100%;
  }
}

.footer {
  text-align: right;
  padding: 20px 0;
}
</style>